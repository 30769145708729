/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { ScaleIcon, ThumbUpIcon, ShieldCheckIcon, UserGroupIcon } from '@heroicons/react/outline'

const features = [
  {
    name: '1er réseau national français',
    description:
      '',
    icon: ThumbUpIcon,
  },
  {
    name: 'Disponible 7j/7',
    description:
      '',
    icon: UserGroupIcon,
  },
  {
    name: "Prix ferme et fixé à l'avance",
    description:
      '',
    icon: ScaleIcon,
  },
  {
    name: 'Gestes de premiers secours',
    description:
      '',
    icon: ShieldCheckIcon,
  },
]

/*
<h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Transactions</h2>
<p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Lorem ipsum dolor sit amet consect adipisicing elit. Possimus magnam voluptatum cupiditate veritatis in
            accusamus quisquam.
          </p>

*/

//<dd className="mt-2 ml-16 text-base text-gray-500">{feature.description}</dd>

export default function Example() {
  return (
    <div className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          
          <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
          Pourquoi choisir blicko ?
          </h2>
          
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {features.map((feature) => (
              <div key={feature.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-[#3333e6] text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="py-3 ml-16 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
