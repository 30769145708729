/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { CheckIcon } from '@heroicons/react/outline'
import { ExternalLinkIcon } from '@heroicons/react/solid'
import { StaticImage } from "gatsby-plugin-image"


export default function Section3() {
  return (
    <div>
      <div className="bg-[#3333e6] py-8">
          <div className="max-w-7xl mx-auto text-center py-4 px-4 sm:px-6 lg:py-4 lg:px-16">
              <h2 className="text-3xl font-extrabold tracking-tight text-white sm:text-4xl">
              <span className="block">Dépannage plomberie et travaux</span>
              </h2>
              <p className="text-2xl font-extrabold tracking-tight text-white sm:text-2xl">
                Réalisez des économies avec blicko
              </p>
              <p className="mt-4 text-lg leading-6 text-white">
              Un problème de plomberie ? Montrez-le nous par visio !<br />
  Avant d’envisager un déplacement inutile, nos plombiers expérimentés vous aident à trouver une solution à distance.<br />
  Ce n’est que si nous nous déplaçons que le service devient payant.<br />
  Avec un prix forfaitaire pour éviter les mauvaises surprises !<br />
              </p>
              <div className="mt-8 flex justify-center">
                  <div className="inline-flex rounded-md shadow mr-4">
                      <a
                      href="/reparations-plomberie/"
                      className="bg-white inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-500 border-[#3333e6]"
                      ><ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-500" aria-hidden="true" />&nbsp;&nbsp;Exemples de dépannage plomberie
                      </a>
                  </div>
                  <div className="inline-flex rounded-md shadow">
                      <a
                      href="/devis/contact/"
                      className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 hover:bg-orange-700"
                      ><ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-white" aria-hidden="true" />&nbsp;&nbsp;Je contacte mon plombier
                      </a>
                  </div>
              </div>
              <p className="mt-1 text-sm text-white">
                Réponse dans l'heure de 7h à 20h 7 jours sur 7
              </p>
          </div>

      </div>

      <div className="bg-gray-100 py-8">
          

          <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:py-12 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
              <div className="mt-4 lg:mt-0 lg:col-span-1">
              <dl className="space-y-10 sm:space-y-5 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                  
                  <div className="relative pb-4">
                      <dt>
                      <CheckIcon className="absolute h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                      <h3 className="ml-9 text-xl leading-6 font-medium text-[#3333e6]">Les réparations courantes</h3>
                      </dt>
                      <dd className="mt-2 ml-9 text-base text-gray-500">
                        - Réparer une fuite sur un tuyau <br />
                        - Remplacer un robinet qui fuit<br />
                        - Réparer une fuite dans les toilettes<br />
                        - Régler un flotteur de toilettes<br />
                        - Déboucher des toilettes<br />
                        - Vidanger un chauffe-eau
                      </dd>
                  </div>

                  <div className="relative pb-4">
                      <dt>
                      <CheckIcon className="absolute h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                      <h3 className="ml-9 text-xl leading-6 font-medium text-[#3333e6]">Réparation robinetterie</h3>
                      </dt>
                      <dd className="mt-2 ml-9 text-base text-gray-500">
                        - Faire les joints silicones<br />
                        - Nettoyer un mousseur<br />
                        - Remplacer joint col de cygne<br />
                        - Remplacer clapet d'un mélangeur<br />
                        - Remplacer siège d'un robinet<br />
                        - Remplacer disque en céramiques<br />
                        - Installer un robinet autoperceur
                      </dd>
                  </div>

                  <div className="relative pb-4">
                      <dt>
                      <CheckIcon className="absolute h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                      <h3 className="ml-9 text-xl leading-6 font-medium text-[#3333e6]">Réparation canalisations</h3>
                      </dt>
                      <dd className="mt-2 ml-9 text-base text-gray-500">
                        - Déboucher avec une ventouse<br />
                        - Déboucher avec une pompe<br />
                        - Démonter / nettoyer un siphon<br />
                        - Déboucher avec un furet<br />
                        - Poser un flexible d'évacuation<br />
                        - Poser une évacuation de machine à laver<br />
                        - Remplacer un joint<br />
                        - Refaire une brasure tendre
                      </dd>
                  </div>

              </dl>
              </div>
              <div>
                  <div>
                    <StaticImage
                      className="object-cover shadow-lg rounded-lg lg:h-full"
                      src="../../images/plombier.jpg"
                      alt="Plombier"
                    />
                  </div>
              </div>
              <div className="mt-12 lg:mt-0 lg:col-span-1">
              <dl className="space-y-10 sm:space-y-5 sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                  
                  <div className="relative">
                      <dt>
                      <CheckIcon className="absolute h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                      <h3 className="ml-9 text-xl leading-6 font-medium text-[#3333e6]">Réparation sanitaires</h3>
                      </dt>
                      <dd className="mt-2 ml-9 text-base text-gray-500">
                        - Réparer un lavabo ébréché<br />
                        - Réparer l'email d'une baignoire / évier<br />
                        - Retirer un joint d'étanchéité ancien silicone<br />
                        - Réaliser un nouveau joint d'étanchéité silicone<br />
                        - Réparer une chasse d'eau
                      </dd>
                  </div>

                  <div className="relative">
                      <dt>
                      <CheckIcon className="absolute h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                      <h3 className="ml-9 text-xl leading-6 font-medium text-[#3333e6]">Réparation eau chaude sanitaire</h3>
                      </dt>
                      <dd className="mt-2 ml-9 text-base text-gray-500">
                        - Chauffe eaux électriques<br />
                        - Réarmer la sécurité du thermostat électrique<br />
                        - Détartrer le chauffe eau<br />
                        - Détartrer partie électrique chauffe eaux électrique / cumulus<br />
                        - Remplacement du cumulus<br />
                        - Fuite remplacement du groupe de sécurité du cumulus<br />
                        - Fuite remplacement du siphon du groupe de sécurité du cumulus
                      </dd>
                  </div>

                  <div className="relative">
                      <dt>
                      <CheckIcon className="absolute h-6 w-6 text-[#3333e6]" aria-hidden="true" />
                      <h3 className="ml-9 text-xl leading-6 font-medium text-[#3333e6]">Devis, assistance et contact</h3>
                      </dt>
                      <dd className="mt-2 ml-9 text-base text-gray-500"></dd>
                  </div>
                  
              </dl>
              </div>
          </div>
      </div>
    </div>
  )
}
