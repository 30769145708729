/* This example requires Tailwind CSS v2.0+ */
import React from "react"

import artinov from '../../images/artinov.png'
import bpifrance from '../../images/bpifrance.png'
import cma from '../../images/cma.png'
import rer from '../../images/laureat-RE-rhone.png'

export default function Example() {
  return (

    <div className="bg-white">
        <div className="max-w-7xl mx-auto text-center py-8 px-4 sm:px-6 lg:py-8 lg:px-16">
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <span className="block">Ils nous font confiance</span>
            </h2>
            <p className="mt-4 text-lg text-gray-500 text-center">
              
              <img className="inline h-20 mb-8 lg:h-32 px-8 grayscale" src={bpifrance} alt="BPI France" />
              <img className="inline h-20 mb-8 lg:h-32 px-8 grayscale" src={artinov} alt="artinov" />
              <img className="inline h-20 mb-8 lg:h-32 px-8 grayscale" src={cma} alt="Chambre des Métiers et de l'Artisanat" />
              <img className="inline h-20 mb-8 lg:h-32 px-8 grayscale" src={rer} alt="rer" />

            </p>
        </div>
    </div>
  )
}
