/* This example requires Tailwind CSS v2.0+ */
import React from "react"

import 'vanilla-cookieconsent/dist/cookieconsent.css';
import CookieConsentComponent from '../CookieConsentComponent/CookieConsentComponent';

import KdpBannerBas from "../bannerbas"

const currentYear = new Date().getFullYear();

const navigation = {
    main: [
      { name: 'Mentions légales', href: '/nous-connaitre/legal/mentions-legales/' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/blickoFr',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: 'Twitter',
        href: 'https://twitter.com/blickoFr',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
      {
        name: 'LinkedIn',
        href: 'https://www.linkedin.com/company/blicko/',
        icon: (props) => (
          <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
    ],
  }
  
  export default function Footer() {
    return (
      <footer className="bg-gray-100">
        <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
            <p className="mt-15 text-center text-base text-gray-400"><strong>blicko par Expertise Plomberie SAS</strong>
              <br />C’est votre proche dans la plomberie disponible et accessible 24/24 7/7.
              <br />Ne vous grattez plus la tête pour trouver une solution à votre problématique de plomberie.
              <br /><a href="/plombier-depannage/auvergne-rhone-alpes/rhone/" className="text-gray-500 hover:text-gray-500">Plombier Rhône</a> - <a href="/plombier-depannage/auvergne-rhone-alpes/rhone/lyon/" className="text-gray-500 hover:text-gray-500">Plombier Lyon</a> - <a href="/plombiers/villeurbanne/" className="text-gray-500 hover:text-gray-500">Plombier Villeurbanne</a>
            </p>
            <p className="mt-15 text-center text-base text-gray-400"></p>
            <nav className="mt-2 -mx-5 -my-2 flex flex-wrap justify-center" aria-label="Footer">
                {navigation.main.map((item) => (
                <div key={item.name} className="px-5 py-2">
                    <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                    {item.name}
                    </a>
                </div>
                ))}
            </nav>
            <div className="mt-2 flex justify-center space-x-6">
                {navigation.social.map((item) => (
                <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
                ))}
            </div>
            <p className="mt-2 text-center text-sm text-gray-400">&copy; 2020 - {currentYear} - blicko - Tous Droits Réservés.</p>
        </div>

        <KdpBannerBas />

        <CookieConsentComponent />
      </footer>
    )
  }
  