import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { PhoneIcon } from '@heroicons/react/outline'


export default function Section1() {
  return (
    <div className="relative bg-white overflow-hidden">
      <main className="lg:mx-auto lg:max-w-7xl lg:pb-16 lg:relative">
        <svg
          className="z-10 hidden lg:block absolute inset-y-0 h-full w-48 text-white transform right-1/2"
          fill="currentColor"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
          aria-hidden="true"
        >
          <polygon points="50,0 100,0 100,100 95,100" />
        </svg>
        <div className="hidden mx-auto lg:w-160 text-right lg:text-left lg:block object-cover lg:max-h-80 lg:min-h-80">
          <StaticImage
            className="mx-auto inset-0 w-600 h-full object-cover"
            src="../../images/equipe-professionnel-plombier-urgence-blicko-pro.jpeg"
            alt="Equipe blicko"
          />
        </div>
        <div className="bg-white relative w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2 lg:h-full">
          <div className="m-4 pt-4 lg:pt-4 sm:text-center md:max-w-2xl lg:ml-8 lg:col-span-6 lg:text-left lg:w-120">
            <h1>
              <span className="mt-1 block text-3xl tracking-tight font-extrabold sm:text-3xl xl:text-4xl">
                <span className="block text-gray-900">Plombiers à Lyon,<br /> Villeurbanne et alentours.</span>
                <span className="block text-[#3333e6]">RDV rapide.</span>
              </span>
            </h1>
            <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
              Prenez rendez-vous au <a href="tel:0428295016"><span className="text-[#3333e6] font-bold">04 28 29 50 16</span></a> pour être dépanné en moins de 48h avec un de nos plombiers proches de chez vous.
            </p>
            <div className="relative mt-8 sm:max-w-lg sm:mx-auto sm:text-center lg:text-left lg:mx-0">
              <form action="https://formulaire.koudepouce.fr/blicko/devis/rappel/" method="POST" className="mt-3 sm:flex">
                <label htmlFor="tel" className="sr-only">
                  Téléphone
                </label>
                <input
                  type="text"
                  name="nom"
                  id="nom"
                  className="block w-full mb-3 lg:mb-0 mr-3 py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:flex-1 border-orange-600"
                  placeholder="Nom"
                  required
                />
                <input
                  type="number"
                  name="telephone"
                  id="tel"
                  className="block w-full py-3 text-base rounded-md placeholder-gray-500 shadow-sm focus:ring-blue-500 focus:border-blue-500 sm:flex-1 border-orange-600"
                  placeholder="Votre numéro"
                  required
                />

                <div className="hidden">
                  <label htmlFor="tellepro" className="sr-only">
                    Téllépro
                  </label>
                  <input
                    type="text"
                    name="tellepro"
                    id="tellepro"
                    className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
                    placeholder="Téllépro"
                  />
                </div>

                <button
                  type="submit"
                  className="flex mt-3 w-full px-6 py-3 border border-transparent text-base font-medium rounded-md text-white bg-orange-600 shadow-sm hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:flex-shrink-0 sm:inline-flex sm:items-center sm:w-auto"
                >
                  <PhoneIcon className="flex h-6 w-6 mr-2" aria-hidden="true" />Rappel immédiat
                </button>
              </form>
              <p className="mt-3 text-sm text-gray-500">
                Ou appel direct au 04 28 29 50 16 (gratuit et sans engagement)
              </p>
            </div>
          </div>
        </div>
      </main>



    </div>
  )
}



