/* This example requires Tailwind CSS v2.0+ */
import React from "react"
import { Disclosure } from '@headlessui/react'
import { MinusIcon, PlusIcon } from '@heroicons/react/outline'

const faqs = [
  {
    id: 1,
    question: "Pourquoi faire appel à un plombier ?",
    answer:
      "L’expertise d’un plombier garantit un travail de qualité et réduit les risques d'erreurs ou de dommages supplémentaires. Ils peuvent terminer les travaux plus rapidement grâce à leur expérience et à leurs outils spécialisés, et leur formation en matière de sécurité minimise les risques de blessures ou de dommages matériels. De plus, vous bénéficiez généralement d'une assurance et de garanties sur leur travail, vous offrant une tranquillité d'esprit supplémentaire.",
  },
  {
    id: 2,
    question: "Combien coûte l’intervention d’un plombier à Lyon et Villeurbanne ?",
    answer:
      "Le coût d’un plombier pour un dépannage peut varier en fonction de la nature de l'intervention, la complexité du problème, le temps nécessaire pour effectuer les travaux et la tarification propre à chaque entreprise de plomberie. Chez blicko, nous recherchons le meilleur rapport qualité prix. Certains de nos dépannages sont même gratuits (lorsque les solutions sont simples et évidentes).",
  },
  {
    id: 3,
    question: "Quelles précautions prendre pour choisir un plombier ?",
    answer:
      "Pour choisir un plombier, vérifiez ses qualifications et son expérience. Demandez également des références et consultez les avis des clients précédents. Vérifiez les garanties et les assurances qu'il offre. Demandez des devis détaillés avant intervention pour éviter les mauvaises surprises. Ces précautions vous aideront à choisir un plombier compétent et fiable pour vos besoins en plomberie.",
  },
  {
    id: 4,
    question: "Quels sont les avantages de faire appel à un plombier blicko ?",
    answer:
      "Nous vous aidons à poser un diagnostic de la panne en visio. Vous bénéficiez ainsi d’un premier conseil gratuit à distance réalisé par l’un de nos plombiers.  Si nous devons intervenir, c’est sans frais caché. Nous vous envoyons un devis clair et précis sur le prix des pièces et de la main d'œuvre.",
  },
  {
    id: 5,
    question: "Où trouver les plombiers blicko dans le Rhône ?",
    answer:
      "blicko intervient partout à Lyon et Villeurbanne et alentour (communes du Grand Lyon). Notre agence de Lyon est située à Sainte-Foy-lès-Lyon. Nous pouvons aussi intervenir dans les départements limitrophes du Rhône.",
  },
  // More questions...
]

export default function Section4bis() {
  // return (
  //   <div className="bg-gray-100">
  //     <div className="mx-auto max-w-7xl divide-y divide-gray-900/10 px-6 py-12 sm:py-32 lg:px-8 lg:py-16">
  //       <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Questions fréquentes dépannage de plombiers</h2>
  //       <dl className="mt-10 space-y-8 divide-y divide-gray-900/10">
  //         {faqs.map((faq) => (
  //           <div key={faq.id} className="pt-8 lg:grid lg:grid-cols-12 lg:gap-8">
  //             <dt className="text-base font-semibold leading-7 text-gray-900 lg:col-span-4">{faq.question}</dt>
  //             <dd className="mt-4 lg:col-span-8 lg:mt-0">
  //               <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
  //             </dd>
  //           </div>
  //         ))}
  //       </dl>
  //     </div>
  //   </div>
  // )
  return (
    <div className="bg-gray-100">
      <div className="mx-auto max-w-7xl px-6 py-12 sm:py-16 lg:px-8 lg:py-16">
        <div className="mx-auto max-w-7xl divide-y divide-gray-900/10">
          <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Questions fréquentes dépannage de plombiers</h2>
          <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
            {faqs.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <>
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">{faq.question}</span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <MinusIcon className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <PlusIcon className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                    </Disclosure.Panel>
                  </>
                )}
              </Disclosure>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}

