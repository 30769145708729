import React from "react"
import { Helmet } from "react-helmet"

// HOME V1
// import KdpBanner from "../components/banner"
// import KdpHeader from "../components/headers/header"
// import KdpSection1 from "../components/home/section1"
// import KdpSection2 from "../components/home/section2"
// import KdpSection3 from "../components/home/section3"
// import KdpSection4 from "../components/home/section4"
// import KdpSection5 from "../components/home/section5"
// import KdpSection6 from "../components/home/section6"
// import KdpFooter from "../components/footers/footer"

// HOME V2

import KdpHeader from "../components/headers/headerv2"
import KdpSection1 from "../components/homev2/section1"
import KdpSection3 from "../components/homev2/section3"
import KdpSection4 from "../components/homev2/section4"
import KdpSection4bis from "../components/homev2/section4bis"
import KdpSectionAvis from "../components/homev2/sectionAvis"
import KdpSection5 from "../components/homev2/section5"
import KdpSection6 from "../components/homev2/section6"
import KdpFooter from "../components/footers/footerv2"
import KdpContact from "../components/contacts/contactFormHome"


export default function Index() {
  return (
    <div className="bg-white">
      <Helmet>
        <title>Votre Plombier à Lyon, Villeurbanne et alentours - blicko.fr</title>
        <meta name="description" content="Réalisez des économies avec blicko | Un problème de plomberie ? Montrez-le nous par visio ! Avant d'envisager un déplacement inutile, nos plombiers vous aide à distance." />
      </Helmet>
      <header className="sticky top-0 z-50">
        {/* <KdpBanner /> */}
        <KdpHeader />
      </header>
      <main className="relative">

        {/* <KdpSection1 />
        <KdpSection2 />
        <KdpSection3 />
        <KdpSection4 />
        <KdpSection5 />
        <KdpSection6 /> */}

        <KdpSection1 />
        <KdpSection3 />
        <KdpSection4 />
        <KdpSection4bis />
        <KdpSection5 />
        <KdpSectionAvis />
        <KdpContact />
        <KdpSection6 />

      </main>

      <footer>
        <KdpFooter />
      </footer>
    </div>
  );
}

