import React from "react"
import {
  PhoneIcon,
} from '@heroicons/react/outline'
/* Bannière */
//import { XIcon } from '@heroicons/react/outline'

/*
Bannière
*/

export default function KdpBanner() {
    return (
      <div className="pointer-events-none fixed inset-x-0 bottom-0 sm:px-6 sm:pb-5 lg:px-8 lg:hidden">
        <div className="pointer-events-auto flex items-center justify-between gap-x-6 bg-[#3333e6] px-6 py-2.5 sm:rounded-xl sm:py-3 sm:pl-4 sm:pr-3.5">
          <p className="text-base leading-6 text-white">
            <a href="tel:0428295016">
              <PhoneIcon className="h-5 w-5 mr-1 text-white inline" aria-hidden="true" />&nbsp;&nbsp;<strong className="font-semibold">04 28 29 50 16</strong>
            </a>
          </p>
        </div>
      </div>
    )
  }