import React from "react"

import { ExternalLinkIcon, CheckIcon } from '@heroicons/react/solid'

import { StaticImage } from "gatsby-plugin-image"

// import CarteFrance from '../../images/carte-france.png'

// import { CheckIcon } from '@heroicons/react/20/solid'

const features = [
  {
    name: 'Salle de bains',
    description: 'Douche, Pare douche, Colonne de douche, Barre de douche, Support de douche, Ciel de pluie de douche, Flexible douchette de douche, Inverseur douchette au ciel de pluie pour douche, Douche entreprise, Siphon de douche, Bonde de douche, Baignoire, Pare baignoire, Colonne de baignoire, Barre de baignoire, Support de baignoire, Ciel de pluie pour baignoire, Inverseur (passage douche bain) de baignoire, Siphon de baignoire, Bonde de baignoire, Colonnette de baignoire, Etanchéité (sol / mur), Joints silicone, Joint ciment, Lavabo / vasque / meuble, Bidet, VMC, VMC Simple flux, Sanibroyeur salle de bain ...',
  },
  { name: 'Canalisations', 
    description: "Colonne d'eau,   Canalisation évacuation,   Alimentation,   Tuyauterie,   Vanne d'entrée,   Vanne d'entrée chauffage,   Vanne d'entrée sanitaire,   Robinet d'arrêt,   Robinet d'arrêt chauffage,   Robinet d'arrêt sanitaire,   Joints,   Robinetterie,   Mitigeur,   Mitigeur Mécanique,   Mitigeur Thermostatique,   Mitigeur Cartouche,   Mélangeur,   Joint à clapet,   Colonette,   Réducteur de pression,   Détendeur,   Dépannage évacuation ..."
  },
  {
    name: 'Cuisine et buanderie',
    description: "Arrivée d'eau, Branchement machines, Lave vaisselle, Lave linge, Frigo américain, Alimentation / évacuation, Raccordement, Evier, Robinet, Vanne d'entrée, Sanibroyeur cuisine, Meuble cuisine et plomberie associée, Gaz, Gaz de ville (seulement branchement), Gaz en bouteille (seulement installation), Buanderie ...",
  },
  
  {
    name: 'Système de chauffage',
    description: 'Radiateur type chauffage central, Radiateur type électrique, Radiateur type circulation eau chaude, Sèche-serviette, Sèche-serviette type électrique, Sèche-serviette circulation eau chaude, Sèche-serviette type mixte ...',
  },
  { 
    name: 'WC', 
    description: "Toilette, Toilette suspendu, Douchette intime, Toilette japonaise, Lave main (petit lavabo), Sèche main (locaux pro, ERP), Urinoir, Chasse d'eau, Sanibroyeur WC ..." 
  },
  {
    name: 'Système de production eaux chaudes sanitaires',
    description: "Chauffe-eau, Ballon d'eau, Cumulus ...",
  },
]

export default function Example() {
  return (
    <div className="bg-white py-12 sm:py-16">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          <div>
            <h2 className="text-3xl font-extrabold tracking-tight text-gray-900 sm:text-4xl">Dépannage par équipement de plomberie</h2>
            <div>
              <StaticImage
                className="object-cover shadow-lg rounded-lg lg:w-full mt-8"
                src="../../images/plombier2.jpg"
                alt="Plombier"
              />
            </div>
            <div className="mt-8 flex justify-center">
              <div className="inline-flex rounded-md shadow mr-4">
                  <a
                  href="/plombier/"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-gray-500 border-[#3333e6]"
                  ><ExternalLinkIcon className="-mr-1 ml-3 h-5 w-5 text-gray-500" aria-hidden="true" />&nbsp;&nbsp;Besoin d’un dépannage plomberie ?
                  </a>
              </div>
            </div>
            {/* <p className="mt-6 text-base leading-7 text-gray-600">
              Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste
              dolor cupiditate blanditiis ratione.
            </p> */}
          </div>
          <dl className="col-span-2 grid grid-cols-1 gap-x-8 gap-y-10 text-base leading-7 text-gray-600 sm:grid-cols-2 lg:gap-y-16">
            {features.map((feature) => (
              <div key={feature.name} className="relative pl-9">
                <dt className="font-semibold text-gray-900">
                  <h3 className="text-xl leading-6 font-medium text-[#3333e6]">
                    <CheckIcon className="absolute left-0 top-1 h-5 w-5 text-indigo-500" aria-hidden="true" />
                    {feature.name}
                  </h3>
                </dt>
                <dd className="mt-2">{feature.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  )
}
